import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class SapLogService {
  header = this.globalService.getHeader();
  url: string = this.globalService.url + '/v2/admin';

  constructor(private globalService: GlobalService, private http: HttpClient) {}

  /**
   * Get Banner List
   * @param limit
   * @param offset
   * @param param
   */
  getSapLogs(limit, offset, param) {
    return this.http.get(
      `${this.url}/sap/log?limit=${limit}&offset=${offset}${param}`,
      {
        headers: this.header,
      }
    );
  }

  /**
   * Get Banner List
   * @param limit
   * @param offset
   * @param param
   */
  getPrimaryProduct(limit, offset, param) {
    return this.http.get(
      `${this.globalService.url}/v1/admin/outlets/sap/product?limit=${limit}&offset=${offset}${param}`,
      {
        headers: this.header,
      }
    );
  }
}
