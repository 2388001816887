export class Order {
  CSTotalQty: number;
  EATotalQty: number;
  cancelReason: string;
  comment: string;
  deliveryAddress: string;
  deliveryAt: string;
  deliveryDriver: string;
  deliveryRouteId: number;
  deliveryStatus: string;
  deliveryTruck: string;
  distributorAddress: string;
  distributorCode: string;
  distributorId: number;
  distributorName: string;
  id: number;
  orderCode: string;
  orderType: string;
  orderedAt: string;
  outletAddress: string;
  outletCode: string;
  outletId: number;
  outletName: string;
  outletPhone: string;
  routeName: string;
  saleRepCode: string;
  saleRepId: number;
  saleRepName: string;
  saleRepPhone: string;
  status: string;
  subTotalAmount: number;
  totalAmount: number;
  totalDiscountAmount: number;
  totalVatAmount: number;
  updatedAt: string;
  updatedBy: string;
  createdBy: string;
  orderItems: OrderItem[] = [];
  items: OrderItem[] = [];
  isCheckedStock: boolean;
  PONo: string;
  sap: any;
  payload: any;
  DONo: string;
  SAPKey: string;
  SAPSONo: string;
  totalQty: number;
  orderAt: string;
  deliveryNote: string;
  invoiceNo: string;
  invoiceType?: string;
  customerName: string;
  iOrderPONo: string | number;
  totalAmountUS: number;
  creditTerm: string;
  createdAt: string;
  customerCode: string;
  clientPONo: string;
  sapStatus: boolean;
  ayoOrderNo: string;
  ayoInvoiceNo: string;
  ayoStatus: boolean | number;
}

export class OrderItem {
  id: number;
  CSDiscountRate: number;
  CSSubTotalAmount: number;
  CSTotalAmount: number;
  CSTotalDiscountAmount: number;
  CSTotalQty: number;
  CSUnitPrice: number;
  EADiscountRate: number;
  EASubTotalAmount: number;
  EATotalAmount: number;
  EATotalDiscountAmount: number;
  EATotalQty: number;
  EAUnitPrice: number;
  capacity: number;
  isPromotion: boolean;
  orderId: number;
  packSize: string;
  productCode: string;
  productId: number;
  productName: string;
  promotionId: number;
  subTotalAmount: number;
  totalAmount: number;
  totalDiscountAmount: number;
  oldCSTotalQty: number;
  oldEATotalQty: number;
  CSAmount: number;
  CSQty: number;
  EAAmount: number;
  EAQty: number;
  UoMName: string;
  UoM: string;
  barCode: string;
  createdAt: string;
  deliveryOrderId: 1;
  description: string;
  itemCode: string;
  quantity: number;
  remark: string;
  unitPrice: number;
  updatedAt: string;
  CSAfterDiscountPrice: number;
  EAAfterDiscountPrice: number;
}

export class OrderTotal {
  constructor(
    CSTotalQty = 0,
    EATotalQty = 0,
    subTotalAmount = 0,
    totalAmount = 0,
    totalDiscountAmount = 0,
    totalVatAmount = 0
  ) {
    this.CSTotalQty = CSTotalQty;
    this.EATotalQty = EATotalQty;
    this.subTotalAmount = subTotalAmount;
    this.totalAmount = totalAmount;
    this.totalDiscountAmount = totalDiscountAmount;
    this.totalVatAmount = totalVatAmount;
  }
  CSTotalQty: number;
  EATotalQty: number;
  subTotalAmount: number;
  totalAmount: number;
  totalDiscountAmount: number;
  totalVatAmount: number;
}

export class PurchaseOrder {
  id: number;
  status: string;
  customer: string;
  poNumber: number;
  sapSoNumber: number;
  iOrderQty: number;
  iOrderAmount: number;
  creditTerms: string;
  deliveryNote: string;
  createdBy: string;
  createdAt: string;
  modifiedBy: string;
  modifiedAt: string;
}

export class DeliveryOrder {
  id: number;
  status: string;
  customer: string;
  deliveryNumber: number;
  poNumber: number;
  sapSoNumber: number;
  totalQty: number;
  iOrderAmount: number;
  creditTerms: string;
  deliveryNote: string;
  createdBy: string;
  createdAt: string;
  modifiedBy: string;
  modifiedAt: string;
  sapCreatedBy: string;
  sapCreatedAt: string;
  sapModifiedBy: string;
  sapModifiedAt: string;
}

export class Invoice {
  id: number;
  status: string;
  invoiceNumber: number;
  deliveryNumber: number;
  poNumber: number;
  sapSoNumber: number;
  totalQty: number;
  totalAmountBillingType: number;
  paymentTerms: string;
  createdBy: string;
  createdAt: string;
  modifiedBy: string;
  modifiedAt: string;
  sapCreatedBy: string;
  sapCreatedAt: string;
  sapModifiedBy: string;
  sapModifiedAt: string;
}
